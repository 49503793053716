<template>
	<gc-dialog
		title="取消任务"
		:show.sync="visible"
		@close="$emit('close-modal')"
		@cancel="$emit('close-modal')"
		@ok="handleSubmitForm"
		width="460px"
	>
		<el-form :model="modelForm" :rules="rules" ref="modelForm" label-position="top" label-width="100px">
			<el-form-item label="操作人" prop="operationName">
				<el-input size="small" v-model="modelForm.operationName" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="取消原因" prop="reason">
				<el-input
					size="small"
					v-model="modelForm.reason"
					type="textarea"
					:rows="3"
					resize="none"
					placeholder="请输入"
				></el-input>
			</el-form-item>
		</el-form>
	</gc-dialog>
</template>

<script>
import { ruleRequired, ruleMaxLength } from '@/utils/rules'
import { apiCancelFirmwareTask, apiCancelFirmwareDetailTask } from '@/apis/firmware.api'
export default {
	name: 'CancelTaskModal',
	props: {
		taskId: String,
		otaTaskDetailId: String,
	},
	data() {
		return {
			visible: true,
			modelForm: {
				operationName: null,
				reason: null,
			},
			rules: {
				operationName: [ruleRequired(), ruleMaxLength(10)],
				reason: [ruleRequired(), ruleMaxLength(50)],
			},
		}
	},

	methods: {
		handleSubmitForm() {
			this.$refs.modelForm.validate(async valid => {
				if (valid) {
					const API = this.taskId ? apiCancelFirmwareTask : apiCancelFirmwareDetailTask
					const searchParams = {
						...this.modelForm,
						taskId: this.taskId,
						otaTaskDetailId: this.otaTaskDetailId,
					}
					await API(searchParams)
					this.$message.success('取消任务成功')
					this.$emit('close-modal', true)
				}
			})
		},
	},
}
</script>
