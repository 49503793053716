<template>
	<div class="page-layout" v-loading="loading">
		<gc-header headerData="upgradeTask">
			<template #ops>
				<el-button v-click-blur type="primary" @click="taskModalVisible = true">创建任务</el-button>
			</template>
		</gc-header>
		<div class="run-main">
			<div class="serach">
				<gc-custom-search keyWord="批次任务名称" width="300px" :search.sync="form.taskName"></gc-custom-search>
				<gc-custom-search
					keyWord="OTA设备类型"
					width="300px"
					type="select"
					:search.sync="form.otaDeviceType"
					:search-option="deviceTypeOptions"
					needAllForSearch
				></gc-custom-search>
				<gc-custom-search
					keyWord="任务状态"
					width="300px"
					type="select"
					:search.sync="form.status"
					:search-option="taskStatusOptions"
					needAllForSearch
				></gc-custom-search>
				<div class="serach-ops">
					<el-button
						type="primary"
						@click="
							pagination.currentPage = 1
							findTableList()
						"
						v-click-blur
					>
						查询
					</el-button>
					<el-button @click="handleReset" v-click-blur>重置</el-button>
				</div>
				<gc-column-setting
					storageKey="localcolumn-firmware-upgrade-task"
					:columns="columns"
					@update-column="data => (columns = data)"
				></gc-column-setting>
			</div>
			<div class="table-show">
				<gc-table
					border
					showPage
					:columns="columnList"
					:table-data="tableData"
					:current-page.sync="pagination.currentPage"
					:total="pagination.total"
					:pageSize="pagination.pageSize"
					gloabSortDisable
					pageLayout="prev, next, jumper, sizes"
					@current-page-change="currentPageChange"
					@size-change="handleSizeChange"
				></gc-table>
			</div>
		</div>
		<TaskModal
			v-if="taskModalVisible"
			:deviceTypeOptions="deviceTypeOptions"
			:firmwareTypeOptions="firmwareTypeOptions"
			@close-modal="handleCloseModal"
		/>
		<CancelTaskModal v-if="cancelTaskVisible" :taskId="taskId" @close-modal="handleCloseModal" />
	</div>
</template>

<script>
import { apiGetFirmwareTaskPageList, apiGetFirmwareDeviceType, apiDownloadExportFailDetails } from '@/apis/firmware.api'
import { firmwareTypeOptions, firmwareTypeMap, taskStatusOptions } from '@/config/const'
import TaskModal from './components/TaskModal'
import CancelTaskModal from './components/CancelTaskModal.vue'

export default {
	name: 'UpgradeTask',
	components: { TaskModal, CancelTaskModal },
	data() {
		return {
			form: {
				taskName: null,
				otaDeviceType: null,
				status: null,
			},
			columns: [
				{
					key: 'taskName',
					name: '批次任务名称',
					tooltip: true,
					dragDisable: true,
					disabledSelect: true,
					minWidth: 180,
				},
				{
					key: 'otaDeviceTypeName',
					name: 'OTA设备类型',
					tooltip: true,
				},
				{
					key: 'firmwareType',
					name: '固件类型',
					tooltip: true,
					render: (h, row) => {
						return h('span', null, firmwareTypeMap[row.firmwareType])
					},
				},
				{
					key: 'status',
					name: '任务状态',
					width: 180,
					render: (h, row) => {
						const classMap = {
							1: 'dot-message',
							2: 'dot-success',
							3: 'dot-error',
							4: 'dot-error',
						}
						return h('span', { class: classMap[row.status] }, row.statusName)
					},
				},
				{
					key: 'totalCount',
					name: '导入成功数',
					width: 100,
					render: (h, row) => {
						return h('span', null, row.detailStatistics?.totalCount || '--')
					},
				},
				{
					key: 'successCount',
					name: '验证成功数',
					width: 100,
					render: (h, row) => {
						return h('span', null, row.detailStatistics?.successCount || '--')
					},
				},
				{
					key: 'successRate',
					name: '成功率',
					width: 100,
					render: (h, row) => {
						return h('span', null, row.detailStatistics?.successRate || '--')
					},
				},
				{
					key: 'operationName',
					name: '操作人',
				},
				{
					key: 'createStaffName',
					name: '创建人',
				},
				{
					key: 'createTime',
					name: '创建时间',
					minWidth: 180,
					tooltip: true,
				},
				{
					key: 'oprate',
					name: '操作',
					sortDisable: true,
					width: 220,
					fixed: 'right',
					render: (h, row) => {
						return h('div', {}, [
							h(
								'el-button',
								{
									props: {
										type: 'text',
										size: 'medium',
									},
									on: {
										click: () => {
											this.$router.push({
												path: '/firmware/upgrade-task/details',
												query: { taskId: row.taskId },
											})
										},
									},
								},
								'详情',
							),
							row.hasErrorMessageFlag
								? h(
										'el-button',
										{
											props: {
												type: 'text',
												size: 'medium',
											},
											on: {
												click: () => {
													this.downloadFailDetails(row.taskId)
												},
											},
										},
										'下载导入失败明细',
								  )
								: null,
							row.status === '1'
								? h(
										'el-button',
										{
											style: { color: '#e5662e' },
											props: {
												type: 'text',
												size: 'medium',
											},
											on: {
												click: () => {
													this.taskId = row.taskId
													this.cancelTaskVisible = true
												},
											},
										},
										'取消',
								  )
								: null,
						])
					},
				},
			],
			pagination: {
				currentPage: 1,
				pageSize: 20,
				total: 0,
			},
			tableData: [],
			loading: false,
			deviceTypeOptions: [],
			taskStatusOptions,
			firmwareTypeOptions,
			taskId: null,
			taskModalVisible: false,
			cancelTaskVisible: false,
		}
	},

	created() {
		this.findDeviceTypeOptions()
		this.findTableList()
	},

	methods: {
		async findTableList() {
			this.loading = true
			const searchParams = {
				...this.getRequestParams(),
				current: this.pagination.currentPage,
				size: this.pagination.pageSize,
			}
			try {
				const { records = [], total } = await apiGetFirmwareTaskPageList(searchParams)
				this.tableData = records
				this.pagination.total = total
			} finally {
				this.loading = false
			}
		},

		async downloadFailDetails(taskId) {
			const data = await apiDownloadExportFailDetails({ taskId })
			const blob = new Blob([data], { type: 'application/vnd.ms-excel' })
			const link = document.createElement('a')
			link.style.display = 'none'
			link.href = URL.createObjectURL(blob)
			link.download = '失败明细'
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		},

		async findDeviceTypeOptions() {
			const data = await apiGetFirmwareDeviceType(this.tenantId)
			this.deviceTypeOptions = (data || []).map(item => {
				return {
					value: item.otaDeviceType,
					label: item.otaDeviceTypeName,
				}
			})
		},

		getRequestParams() {
			return this.form
		},

		currentPageChange(current) {
			this.pagination.currentPage = current
			this.findTableList()
		},

		handleSizeChange(size) {
			this.pagination.currentPage = 1
			this.pagination.pageSize = size
			this.findTableList()
		},

		handleReset() {
			for (const key in this.form) {
				this.form[key] = null
			}
			this.pagination.currentPage = 1
			this.findTableList()
		},

		handleCloseModal(refresh) {
			this.taskModalVisible = false
			this.cancelTaskVisible = false
			if (refresh) {
				this.handleReset()
			}
		},
	},

	computed: {
		columnList() {
			return this.columns.filter(o => o.selected)
		},

		tenantId() {
			return this.$store.getters.userInfo.tenantId || null
		},
	},
}
</script>
<style lang="scss" scoped>
@import '../../run-manage/runLayout.scss';
</style>
