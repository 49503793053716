<template>
	<gc-dialog
		title="创建任务"
		width="760px"
		:show.sync="visible"
		@close="$emit('close-modal')"
		@cancel="$emit('close-modal')"
		@ok="handleSubmitForm"
		:okLoading="requestLoading"
	>
		<el-form :model="modelForm" :rules="rules" ref="modelForm" label-position="top" label-width="100px">
			<div class="form-block">
				<div class="title">任务内容</div>
				<el-row :gutter="12">
					<el-col :span="8">
						<el-form-item label="批次任务名称" prop="taskName">
							<el-input size="small" v-model="modelForm.taskName" placeholder="请输入"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="OTA设备类型" prop="otaDeviceType">
							<el-select v-model="modelForm.otaDeviceType" placeholder="请选择">
								<el-option
									v-for="item in deviceTypeOptions"
									:key="item.value"
									:label="item.label"
									:value="item.value"
								></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="固件类型" prop="firmwareType">
							<el-select v-model="modelForm.firmwareType" placeholder="请选择">
								<el-option
									v-for="item in firmwareTypeOptions"
									:key="item.value"
									:label="item.label"
									:value="item.value"
								></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="截止时间" prop="taskEndTime">
							<el-date-picker
								v-model="modelForm.taskEndTime"
								type="date"
								placeholder="选择日期时间"
								value-format="yyyy-MM-dd"
								format="yyyy-MM-dd"
								:picker-options="pickerOptions"
							></el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="下载次数限制" prop="downloadLimitCount">
							<el-input
								v-model="modelForm.downloadLimitCount"
								size="small"
								placeholder="1 ~ 10"
								@input="value => (modelForm.downloadLimitCount = value.replace(/[^0-9]/gi, ''))"
							></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="操作人" prop="operationName">
							<el-input v-model="modelForm.operationName" size="small" placeholder="请输入"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="备注" prop="taskDescribe">
							<el-input
								type="textarea"
								v-model="modelForm.taskDescribe"
								placeholder="升级范围、背景信息等"
								:rows="3"
								resize="none"
							></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</div>
			<div class="form-block">
				<div class="title">
					文件导入
					<a style="font-size: 14px" :href="`${publicPath}excel/OTA任务升级模板.xls`">模板下载</a>
				</div>
				<el-row :gutter="12">
					<el-col :span="24">
						<el-form-item prop="deviceList" style="margin-top: 8px">
							<gc-custom-search
								keyWord="设备编号"
								placeholder="请上传文件"
								width="100%"
								:search.sync="modelForm.deviceList"
								showImportBtn
								readonly
							></gc-custom-search>
						</el-form-item>
					</el-col>
				</el-row>
			</div>
		</el-form>
	</gc-dialog>
</template>

<script>
import { ruleRequired, ruleMaxLength } from '@/utils/rules'
import { apiGetFirmwarePageList, apiSaveFirmwareTask, apiDownloadExportFailDetails } from '@/apis/firmware.api'
export default {
	name: 'FirmwareTaskModal',
	props: {
		deviceTypeOptions: {
			type: Array,
			default: () => [],
		},
		firmwareTypeOptions: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			publicPath: process.env.BASE_URL,
			visible: true,
			modelForm: {
				taskName: null,
				otaDeviceType: null,
				firmwareType: 1,
				taskEndTime: null,
				downloadLimitCount: null,
				operationName: null,
				taskDescribe: null,
				deviceList: [],
			},
			rules: {
				taskName: [ruleRequired(), ruleMaxLength(50)],
				otaDeviceType: [ruleRequired('请选择')],
				firmwareType: [ruleRequired('请选择')],
				taskEndTime: [ruleRequired('请选择')],
				downloadLimitCount: [
					ruleRequired(),
					{
						pattern: /^(10|[1-9])$/,
						message: '只能输入1到10之间的整数',
						trigger: 'change',
					},
				],
				operationName: [ruleRequired(), ruleMaxLength(10)],
				deviceList: [ruleRequired('请上传文件')],
				taskDescribe: [ruleMaxLength(500)],
			},
			firmwareOptions: [],
			pickerOptions: {
				disabledDate(time) {
					const now = new Date()
					now.setHours(0, 0, 0, 0)
					return time.getTime() < now.getTime()
				},
			},
			requestLoading: false,
		}
	},
	created() {
		this.findFirmwareListOptions()
	},

	methods: {
		handleSubmitForm() {
			this.$refs.modelForm.validate(async valid => {
				if (valid) {
					this.requestLoading = true
					try {
						const searchParams = {
							...this.modelForm,
							taskEndTime: this.modelForm.taskEndTime + ' 23:59:59',
							deviceList: this.modelForm.deviceList.map(m => {
								return {
									deviceNo: m['设备编号'] ? String(m['设备编号']).trim() : '',
									preVersion: m['前置版本号'] ? String(m['前置版本号']).trim() : '',
									targetVersion: m['目标版本号'] ? String(m['目标版本号']).trim() : '',
								}
							}),
						}
						const { taskId, errorCount } = await apiSaveFirmwareTask(searchParams)
						if (errorCount === 0) {
							this.$message.success('创建任务成功')
						} else {
							const h = this.$createElement
							this.$msgbox({
								title: '任务失败明细下载',
								message: h('p', { style: 'padding-top: 12px' }, [
									h('span', null, `当前有 ${errorCount} 台设备导入失败，`),
									h(
										'i',
										{
											style: 'color: #2080f7; cursor: pointer',
											on: {
												click: () => this.downloadFailDetails(taskId),
											},
										},
										'点击下载',
									),
									h('span', null, ' 失败明细。'),
								]),
								showCancelButton: false,
								showClose: false,
								closeOnClickModal: false,
								closeOnPressEscape: false,
							})
						}
						this.$emit('close-modal', true)
					} finally {
						this.requestLoading = false
					}
				}
			})
		},

		async findFirmwareListOptions() {
			const data = await apiGetFirmwarePageList({ current: 1, size: 500 })
			this.firmwareOptions = data.records
		},

		async downloadFailDetails(taskId) {
			const data = await apiDownloadExportFailDetails({ taskId })
			const blob = new Blob([data], { type: 'application/vnd.ms-excel' })
			const link = document.createElement('a')
			link.style.display = 'none'
			link.href = URL.createObjectURL(blob)
			link.download = '失败明细'
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		},
	},
}
</script>
<style lang="scss" scoped>
.form-block {
	.title {
		font-size: 17px;
		margin-bottom: 8px;
		color: #000;
		display: block;
	}
	& + .form-block {
		margin-top: 18px;
	}
}
::v-deep .el-form-item {
	.el-upload {
		display: block;
		.el-upload-dragger {
			width: 100%;
			height: 142px;
			.el-icon-upload {
				font-size: 46px;
				margin: 30px 0 0;
			}
			.el-upload__text {
				font-size: 12px;
				line-height: 1;
			}
			.filename {
				margin-top: 52px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				padding: 0 12px;
			}
		}
	}
	.el-upload__tip {
		margin-top: 0;
	}
}
</style>
